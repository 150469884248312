import * as messagesApi from '@/api/messages/messages'

const messages = {
  state: {
  },
  mutations: {
  },
  actions: {
    getMessages ({ commit }, data) {
      return new Promise((resolve, reject) => {
        messagesApi.getMessages(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showMessage ({ commit }, data) {
      return new Promise((resolve, reject) => {
        messagesApi.showMessage(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storeMessage ({ commit }, data) {
      return new Promise((resolve, reject) => {
        messagesApi.storeMessage(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateMessage ({ commit }, data) {
      return new Promise((resolve, reject) => {
        messagesApi.updateMessage(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyMessage ({ commit }, data) {
      return new Promise((resolve, reject) => {
        messagesApi.destroyMessage(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default messages
