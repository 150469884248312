import request from '@/utils/request'

export function getPhoneBooks (data) {
  return request({
    url: '/api/admin/phone_books',
    method: 'post',
    data: data
  })
}

export function showPhoneBook (data) {
  return request({
    url: '/api/admin/phone_books/show',
    method: 'post',
    data: data
  })
}

export function storePhoneBook (data) {
  return request({
    url: '/api/admin/phone_books/store',
    method: 'post',
    data: data
  })
}

export function updatePhoneBook (data) {
  return request({
    url: '/api/admin/phone_books/update',
    method: 'post',
    data: data
  })
}

export function destroyPhoneBook (data) {
  return request({
    url: '/api/admin/phone_books/destroy',
    method: 'post',
    data: data
  })
}
