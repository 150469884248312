import * as devicesApi from '@/api/devices/devices'

const devices = {
  state: {
  },
  mutations: {
  },
  actions: {
    getDevices ({ commit }, data) {
      return new Promise((resolve, reject) => {
        devicesApi.getDevices(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showDevice ({ commit }, data) {
      return new Promise((resolve, reject) => {
        devicesApi.showDevice(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    updateDevice ({ commit }, data) {
      return new Promise((resolve, reject) => {
        devicesApi.updateDevice(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyDevice ({ commit }, data) {
      return new Promise((resolve, reject) => {
        devicesApi.destroyDevice(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default devices
