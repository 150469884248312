import request from '@/utils/request'

export function getPhones (data) {
  return request({
    url: '/api/admin/phones',
    method: 'post',
    data: data
  })
}

export function importPhones (data) {
  return request({
    url: '/api/admin/phones/import',
    method: 'post',
    data: data
  })
}

export function destroyPhone (data) {
  return request({
    url: '/api/admin/phones/destroy',
    method: 'post',
    data: data
  })
}

export function getQualifiedPhones (data) {
  return request({
    url: '/api/admin/phones/get_qualified',
    method: 'post',
    data: data
  })
}
