import request from '@/utils/request'

export function getMessages (data) {
  return request({
    url: '/api/admin/messages',
    method: 'post',
    data: data
  })
}

export function showMessage (data) {
  return request({
    url: '/api/admin/messages/show',
    method: 'post',
    data: data
  })
}

export function storeMessage (data) {
  return request({
    url: '/api/admin/messages/store',
    method: 'post',
    data: data
  })
}

export function updateMessage (data) {
  return request({
    url: '/api/admin/messages/update',
    method: 'post',
    data: data
  })
}

export function destroyMessage (data) {
  return request({
    url: '/api/admin/messages/destroy',
    method: 'post',
    data: data
  })
}
