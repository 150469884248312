import Vue from 'vue'
import Vuex from 'vuex'
import state from '@/store/state'
import mutations from '@/store/mutations'
import getters from '@/store/getters'

import admins from '@/store/modules/admins/admins'
import dashboard from '@/store/modules/dashboard/dashboard'
import configs from '@/store/modules/configs/configs'
import devices from '@/store/modules/devices/devices'
import phoneBooks from '@/store/modules/phoneBooks/phoneBooks'
import phones from '@/store/modules/phoneBooks/phones'
import messages from '@/store/modules/messages/messages'
import dialingLogs from '@/store/modules/dialingLogs/dialingLogs'
import sendingLogs from '@/store/modules/sendingLogs/sendingLogs'
import tags from '@/store/modules/tags/tags'

// TinyMCE
import tinymce from '@/store/modules/tinymce/tinymce'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admins,
    dashboard,
    configs,
    devices,
    phoneBooks,
    phones,
    messages,
    dialingLogs,
    sendingLogs,
    tags,
    // TinyMCE
    tinymce,
  },
  state,
  mutations,
  getters
})
