import * as phonesApi from '@/api/phoneBooks/phones'

const phones = {
  state: {
  },
  mutations: {
  },
  actions: {
    getPhones ({ commit }, data) {
      return new Promise((resolve, reject) => {
        phonesApi.getPhones(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    importPhones ({ commit }, data) {
      return new Promise((resolve, reject) => {
        phonesApi.importPhones(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyPhone ({ commit }, data) {
      return new Promise((resolve, reject) => {
        phonesApi.destroyPhone(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    getQualifiedPhones ({ commit }, data) {
      return new Promise((resolve, reject) => {
        phonesApi.getQualifiedPhones(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default phones
