import request from '@/utils/request'

export function getDevices (data) {
  return request({
    url: '/api/admin/devices',
    method: 'post',
    data: data
  })
}

export function showDevice (data) {
  return request({
    url: '/api/admin/devices/show',
    method: 'post',
    data: data
  })
}

export function updateDevice (data) {
  return request({
    url: '/api/admin/devices/update',
    method: 'post',
    data: data
  })
}

export function destroyDevice (data) {
  return request({
    url: '/api/admin/devices/destroy',
    method: 'post',
    data: data
  })
}
