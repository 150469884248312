import request from '@/utils/request'
import md5 from 'md5'

export function login (data) {
  data.password = md5(data.password).toUpperCase()
  return request({
    url: '/api/admin/admin/login',
    method: 'post',
    data: data
  })
}

export function logout () {
  return request({
    url: '/api/admin/admin/logout',
    method: 'post'
  })
}

export function checkSession () {
  return request({
    url: '/api/admin/admin/check_session',
    method: 'post'
  })
}

export function updateAdminProfile (data) {
  return request({
    url: '/api/admin/admin/update_admin_profile',
    method: 'post',
    data: data
  })
}

export function updateAdminLocale (data) {
  return request({
    url: '/api/admin/admin/update_admin_locale',
    method: 'post',
    data: data
  })
}

export function updateAdminPassword (data) {
  data.current_password = data.current_password ? md5(data.current_password).toUpperCase() : ''
  data.new_password = data.new_password ? md5(data.new_password).toUpperCase() : ''
  data.new_password_confirmation = data.new_password_confirmation ? md5(data.new_password_confirmation).toUpperCase() : ''
  return request({
    url: '/api/admin/admin/update_admin_password',
    method: 'post',
    data: data
  })
}

export function updateAdminAvatar (data) {
  return request({
    url: '/api/admin/admin/update_admin_avatar',
    method: 'post',
    data: data
  })
}

export function getAdmins (data) {
  return request({
    url: '/api/admin/admin/get_admins',
    method: 'post',
    data: data
  })
}

export function showAdmin (data) {
  return request({
    url: '/api/admin/admin/show_admin',
    method: 'post',
    data: data
  })
}

export function storeAdmin (data) {
  data.password = md5(data.password).toUpperCase()
  return request({
    url: '/api/admin/admin/store_admin',
    method: 'post',
    data: data
  })
}

export function updateAdmin (data) {
  data.password = data.password ? md5(data.password).toUpperCase() : ''
  return request({
    url: '/api/admin/admin/update_admin',
    method: 'post',
    data: data
  })
}
