import * as phoneBooksApi from '@/api/phoneBooks/phoneBooks'

const phoneBooks = {
  state: {
  },
  mutations: {
  },
  actions: {
    getPhoneBooks ({ commit }, data) {
      return new Promise((resolve, reject) => {
        phoneBooksApi.getPhoneBooks(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    showPhoneBook ({ commit }, data) {
      return new Promise((resolve, reject) => {
        phoneBooksApi.showPhoneBook(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    storePhoneBook ({ commit }, data) {
      return new Promise((resolve, reject) => {
        phoneBooksApi.storePhoneBook(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    updatePhoneBook ({ commit }, data) {
      return new Promise((resolve, reject) => {
        phoneBooksApi.updatePhoneBook(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

    destroyPhoneBook ({ commit }, data) {
      return new Promise((resolve, reject) => {
        phoneBooksApi.destroyPhoneBook(data).then(res => {
          if (res.code === 2000) {
            resolve()
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default phoneBooks
