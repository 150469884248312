export default {
  search_info: '最後搜尋於 {at}，共有 {count} 項結果（搜尋時間：{sec}秒）',
  delete_note: '注意：此操作無法恢復',
  delete_msg: '您確定要刪除 <b>{name}</b> 嗎？',

  pick_a_date_or_date_range: '選擇一個日期或日期範圍',

  incorrect_email_or_password: '錯誤的Email或密碼',
  email_format_is_incorrect: '電子郵件格式不正確',
  wrong_format: '格式錯誤',
  mobile_number_has_been_registered: '手機號碼已被註冊',
  try_again_later: '稍後再試',
  the_verification_code_is_incorrect_or_expired: '驗證碼不正確或已過期',
  import_file_type_restriction: '匯入檔案類型限制：{type}',
  import_file_size_limit: '匯入檔案大小限制：{size}',
  limit_on_the_number_of_imported_data: '匯入資料筆數限制：{count} 筆',
  auto_split_remark: '如果電話簿中的電話號碼超過 {count} 個，系統將自動拆分電話號碼至新的電話簿中。',
  search_is_limited_to_a_date_range_of_up_to_one_month: '只開放搜尋一個月內的資料。',

  sun: '日',
  mon: '一',
  tue: '二',
  wed: '三',
  thu: '四',
  fri: '五',
  sat: '六',

  jan: '1 月',
  feb: '2 月',
  mar: '3 月',
  apr: '4 月',
  may: '5 月',
  jun: '6 月',
  jul: '7 月',
  aug: '8 月',
  sep: '9 月',
  oct: '10 月',
  nov: '11 月',
  dec: '12 月',

  seconds: '秒',
  minutes: '分',
  hours: '時',
  days: '日',
  weeks: '週',
  months: '月',
  years: '年',

  second: '秒',
  minute: '分',
  hour: '時',
  day: '日',
  week: '週',
  month: '月',
  year: '年',

  on: '開',
  off: '關',
  login: '登入',
  logout: '登出',
  login_to_your_account: '登入您的帳戶',
  forgot_password: '忘記密碼？',
  lang: '語言',
  main: '主頁',
  dashboard: '儀表板',
  profile: '個人資料',
  name: '名稱',
  email: '電子郵件',
  calling_code: '國家代碼',
  phone: '電話',
  cellphone: '手機',
  telephone: '市話',
  mobile_number: '手機號碼',
  username: '使用者名稱',
  password: '密碼',
  change_password: '更改密碼',
  current_password: '當前密碼',
  new_password: '新密碼',
  new_password_confirmation: '重複輸入密碼',
  generate_password_msg: '點擊右方按鈕以生成隨機密碼',
  password_min_msg: '密碼長度必須至少為 8 個字符',
  leave_blank_if_not_modified: '如不修改則留空',
  edit: '編輯',
  save: '儲存',
  create: '新增',
  read: '讀取',
  update: '更新',
  delete: '刪除',
  ok: '確定',
  cancel: '取消',
  yes: '是',
  no: '否',
  enable: '啟用',
  disable: '禁用',
  visible: '可見',
  invisible: '不可見',
  action: '操作',
  details: '詳細資訊',
  none: '無',
  or: '或',
  please_select_a: '請選擇一個',
  search_a: '搜尋一個',
  change_avatar: '變更頭像',
  created_successfully: '新增成功',
  updated_successfully: '更新成功',
  uploaded_successfully: '上傳成功',
  deleted_successfully: '刪除成功',
  create_time: '新增時間',
  update_time: '更新時間',
  last_update_time: '最近更新時間',
  last_enable_time: '最近啟用時間',
  last_status_change_time: '最近狀態變更時間',
  last_used_time: '最近使用時間',
  last_data_time: '最近資料時間',
  receive_time: '接收時間',
  required_fields_cannot_be_empty: '必填欄位不能為空',
  management: '管理',
  manager: '管理者',
  settings: '設定',
  system_management: '系統管理',
  organization: '組織',
  organization_management: '組織管理',
  roles: '權限',
  role_groups: '權限群組',
  accounts: '帳號',
  account: '帳號',
  account_name: '帳號名稱',
  admins: '管理員',
  search: '搜尋',
  advanced_search: '進階搜尋',
  search_mode: '搜尋模式',
  create_account: '新增帳號',
  edit_account: '編輯帳號',
  create_role_group: '新增權限群組',
  edit_role_group: '編輯權限群組',
  set_roles: '設定權限',
  permission_denied: '權限不足',
  title: '標題',
  content: '內容',
  configs: '系統設置',
  edit_config: '編輯設置',
  value: '值',
  backend_management: '後台管理',
  devices_management: '設備管理',
  devices: '設備',
  device: '設備',
  type: '類型',
  edit_device: '編輯設備',
  delete_device: '刪除設備',
  phones_management: '電話管理',
  name_cannot_be_repeated: '名稱不能重複',
  phone_books: '電話簿',
  phone_book: '電話簿',
  create_phone_book: '新增電話簿',
  edit_phone_book: '編輯電話簿',
  delete_phone_book: '刪除電話簿',
  phone_numbers: '電話號碼',
  phone_number: '電話號碼',
  phone_name: '電話名稱',
  phone_search_results: '電話號碼搜尋結果',
  no_search_results: '無搜尋結果',
  export_search_results: '匯出搜尋結果',
  import_phone_numbers: '匯入電話號碼',
  delete_phone_number: '刪除電話號碼',
  sort: '排序',
  tags: '標籤',
  tag: '標籤',
  messages_management: '訊息管理',
  create_message: '新增訊息',
  edit_message: '編輯訊息',
  delete_message: '刪除訊息',
  last_dialing_logs: '最近撥號紀錄',
  logs_management: '日誌管理',
  dialing_logs: '撥號紀錄',
  sending_logs: '發送紀錄',
  status: '狀態',
  dial_today: '今日撥號',
  send_today: '今日發送',
  tag_statistics: '標籤統計',
  dial_statistics: '撥號統計',
  send_statistics: '發送統計',
  connected: '已接通',
  not_connected: '未接通',
  connection_rate: '接通率',
  remark: '備註',
  remarks: '備註',
  qualified_customer: '有效客戶',
  unqualified_customer: '無效客戶',
  qualification_rate: '有效客戶率',
  qualified_customer_number_transfer: '有效客戶號碼轉存',
}
