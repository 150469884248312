import Vue from 'vue'
import Router from 'vue-router'
import i18n from '@/lang/i18n'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const dashboard = () => import('@/views/dashboard/dashboard')
const profile = () => import('@/views/profile')

const organization_admins = () => import('@/views/organization/admins')

const configs_configs = () => import('@/views/configs/configs')

const devices_devices = () => import('@/views/devices/devices')

const phoneBooks_phoneBooks = () => import('@/views/phoneBooks/phoneBooks')
const phoneBooks_phones = () => import('@/views/phoneBooks/phones')
const phoneBooks_qualified = () => import('@/views/phoneBooks/qualified')

const messages_messages = () => import('@/views/messages/messages')

const logs_dialingLogs = () => import('@/views/logs/dialingLogs')
const logs_sendingLogs = () => import('@/views/logs/sendingLogs')

const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: i18n.t('main'),
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: i18n.t('dashboard'),
          component: dashboard
        },
        {
          path: 'profile',
          name: i18n.t('profile'),
          component: profile
        },
        {
          path: 'organization',
          redirect: '/organization/admins',
          name: i18n.t('organization_management'),
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'admins',
              name: i18n.t('accounts'),
              component: organization_admins,
              meta: {
                role: 'root'
              }
            }
          ]
        },
        {
          path: 'configs',
          name: i18n.t('configs'),
          component: configs_configs,
          meta: {
            role: 'root'
          }
        },
        {
          path: 'devices',
          name: i18n.t('devices_management'),
          component: devices_devices,
          meta: {
            role: 'devices'
          }
        },
        {
          path: 'messages',
          name: i18n.t('messages_management'),
          component: messages_messages,
          meta: {
            role: 'messages'
          }
        },
        {
          path: 'phone_books',
          redirect: '/phone_books/phone_books',
          name: i18n.t('phones_management'),
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'phone_books',
              name: i18n.t('phone_books'),
              component: phoneBooks_phoneBooks,
              meta: {
                role: 'phone_books-phone_books'
              }
            },
            {
              path: 'phones',
              name: i18n.t('phone_numbers'),
              component: phoneBooks_phones,
              meta: {
                role: 'phone_books-phones'
              }
            },
            {
              path: 'qualified',
              name: i18n.t('qualified_customer_number_transfer'),
              component: phoneBooks_qualified,
              meta: {
                role: 'root'
              }
            }
          ]
        },
        {
          path: 'logs',
          redirect: '/logs/dialing_logs',
          name: i18n.t('logs_management'),
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'dialing_logs',
              name: i18n.t('dialing_logs'),
              component: logs_dialingLogs,
              meta: {
                role: 'logs-dialing_logs'
              }
            },
            {
              path: 'sending_logs',
              name: i18n.t('sending_logs'),
              component: logs_sendingLogs,
              meta: {
                role: 'logs-sending_logs'
              }
            }
          ]
        },
      ]
    },
    {
      path: '*',
      redirect: '/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        }
      ]
    }
  ]
}

