import * as sendingLogsApi from '@/api/sendingLogs/sendingLogs'

const sendingLogs = {
  state: {
  },
  mutations: {
  },
  actions: {
    getSendingLogs ({ commit }, data) {
      return new Promise((resolve, reject) => {
        sendingLogsApi.getSendingLogs(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default sendingLogs
