import request from '@/utils/request'

export function getDashboard (data) {
  return request({
    url: '/api/admin/dashboard',
    method: 'post',
    data: data
  })
}

export function getDialStatistics (data) {
  return request({
    url: '/api/admin/dashboard/dial_statistics',
    method: 'post',
    data: data
  })
}

export function getSendStatistics (data) {
  return request({
    url: '/api/admin/dashboard/send_statistics',
    method: 'post',
    data: data
  })
}

export function getTagStatistics (data) {
  return request({
    url: '/api/admin/dashboard/tag_statistics',
    method: 'post',
    data: data
  })
}
