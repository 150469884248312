import * as tagsApi from '@/api/tags/tags'

const tags = {
  state: {
  },
  mutations: {
  },
  actions: {
    getTags ({ commit }, data) {
      return new Promise((resolve, reject) => {
        tagsApi.getTags(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default tags
