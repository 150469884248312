export default {
  search_info: 'Last search at {at}, a total of {count} results (search time: {sec} seconds)',
  delete_note: 'Note: This operation cannot be recovered.',
  delete_msg: 'Are you sure you want to delete <b>{name}</b> ?',

  pick_a_date_or_date_range: 'Pick a date or date range',

  incorrect_email_or_password: 'Incorrect email or password',
  email_format_is_incorrect: 'Email format is incorrect',
  wrong_format: 'Wrong format',
  mobile_number_has_been_registered: 'Mobile number has been registered',
  try_again_later: 'Try again later',
  the_verification_code_is_incorrect_or_expired: 'The verification code is incorrect or expired',
  import_file_type_restriction: 'Import file type restriction:{type}',
  import_file_size_limit: 'Import file size limit: {size}',
  limit_on_the_number_of_imported_data: 'Limit on the number of imported data: {count}',
  auto_split_remark: 'If the number of phone numbers in the phone book exceeds {count}, the system will automatically split and add the phone numbers into a new phone book.',
  search_is_limited_to_a_date_range_of_up_to_one_month: 'Search is limited to a date range of up to one month.',

  sun: 'Sun',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thur',
  fri: 'Fri',
  sat: 'Sat',

  jan: 'Jan',
  feb: 'Feb',
  mar: 'Mar',
  apr: 'Apr',
  may: 'May',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Aug',
  sep: 'Sep',
  oct: 'Oct',
  nov: 'Nov',
  dec: 'Dec',

  seconds: 'Seconds',
  minutes: 'Minutes',
  hours: 'Hours',
  days: 'Days',
  weeks: 'Weeks',
  months: 'Months',
  years: 'Years',

  second: 'Second',
  minute: 'Minute',
  hour: 'Hour',
  day: 'Day',
  week: 'Week',
  month: 'Month',
  year: 'Year',

  on: 'On',
  off: 'Off',
  login: 'Log in',
  logout: 'Log out',
  login_to_your_account: 'Log in to your account.',
  forgot_password: 'Forgot password ?',
  lang: 'Language',
  main: 'Main',
  dashboard: 'Dashboard',
  profile: 'Profile',
  name: 'Name',
  email: 'Email',
  calling_code: 'Calling code',
  phone: 'Phone',
  cellphone: 'Cellphone',
  telephone: 'Telephone',
  mobile_number: 'Mobile number',
  username: 'Username',
  password: 'Password',
  change_password: 'Change password',
  current_password: 'Current password',
  new_password: 'New password',
  new_password_confirmation: 'Repeat password',
  generate_password_msg: 'Click the button on the right to generate a random password',
  password_min_msg: 'Password must be at least 8 characters long',
  leave_blank_if_not_modified: 'Leave blank if not modified',
  edit: 'Edit',
  save: 'Save',
  create: 'Create',
  read: 'Read',
  update: 'Update',
  delete: 'Delete',
  ok: 'OK',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  enable: 'Enable',
  disable: 'Disable',
  visible: 'Visible',
  invisible: 'Invisible',
  action: 'Action',
  details: 'Details',
  none: 'None',
  or: ' or ',
  please_select_a: 'Please select a ',
  search_a: 'Search a ',
  change_avatar: 'Change avatar',
  created_successfully: 'Created successfully',
  updated_successfully: 'Updated successfully',
  uploaded_successfully: 'Uploaded successfully',
  deleted_successfully: 'Deleted successfully',
  create_time: 'Create time',
  update_time: 'Update time',
  last_update_time: 'Last update time',
  last_enable_time: 'Last enable time',
  last_status_change_time: 'Last status change time',
  last_used_time: 'Last used time',
  last_data_time: 'Last data time',
  receive_time: 'Receive time',
  required_fields_cannot_be_empty: 'Required fields cannot be empty',
  management: 'Management',
  manager: 'Manager',
  settings: 'Settings',
  system_management: 'System management',
  organization: 'Organization',
  organization_management: 'Organization management',
  roles: 'Permission',
  role_groups: 'Permission groups',
  accounts: 'Accounts',
  account: 'Account',
  account_name: 'Account name',
  admins: 'Administrators',
  search: 'Search',
  advanced_search: 'Advanced search',
  search_mode: 'Search mode',
  create_account: 'Create account',
  edit_account: 'Edit account',
  create_role_group: 'Create permission group',
  edit_role_group: 'Edit permission group',
  set_roles: 'Set permissions',
  permission_denied: 'Permission denied',
  title: 'Title',
  content: 'Content',
  configs: 'Configurations',
  edit_config: 'Edit configuration',
  value: 'Value',
  backend_management: 'Backend management',
  devices_management: 'Devices management',
  devices: 'Devices',
  device: 'Device',
  type: 'Type',
  edit_device: 'Edit device',
  delete_device: 'Delete device',
  phones_management: 'Phones management',
  name_cannot_be_repeated: 'Name cannot be repeated',
  phone_books: 'Phone books',
  phone_book: 'Phone book',
  create_phone_book: 'Create phone book',
  edit_phone_book: 'Edit phone book',
  delete_phone_book: 'Delete phone book',
  phone_numbers: 'Phone numbers',
  phone_number: 'Phone number',
  phone_name: 'Phone name',
  phone_search_results: 'Phone search results',
  no_search_results: 'No search results',
  export_search_results: 'Export search results',
  import_phone_numbers: 'Import phone numbers',
  delete_phone_number: 'Delete phone number',
  sort: 'Sort',
  tags: 'Tags',
  tag: 'Tag',
  messages_management: 'Message',
  create_message: 'Create message',
  edit_message: 'Edit message',
  delete_message: 'Delete message',
  last_dialing_logs: 'Last dialing Logs',
  logs_management: 'Logs',
  dialing_logs: 'Dialing logs',
  sending_logs: 'Sending logs',
  status: 'Status',
  dial_today: 'Dial today',
  send_today: 'Send today',
  tag_statistics: 'Tag statistics',
  dial_statistics: 'Dial statistics',
  send_statistics: 'Send statistics',
  connected: 'Connected',
  not_connected: 'Not connected',
  connection_rate: 'Connection rate',
  remark: 'Remark',
  remarks: 'Remarks',
  qualified_customer: 'Qualified customer',
  unqualified_customer: 'Unqualified customer',
  qualification_rate: 'Qualification rate',
  qualified_customer_number_transfer: 'Qualified customer number',
}
