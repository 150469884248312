import * as dashboardApi from '@/api/dashboard/dashboard'

const dashboard = {
  state: {
  },
  mutations: {
  },
  actions: {
    getDashboard ({ commit }, data) {
      return new Promise((resolve, reject) => {
        dashboardApi.getDashboard(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    getDialStatistics ({ commit }, data) {
      return new Promise((resolve, reject) => {
        dashboardApi.getDialStatistics(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    getSendStatistics ({ commit }, data) {
      return new Promise((resolve, reject) => {
        dashboardApi.getSendStatistics(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

    getTagStatistics ({ commit }, data) {
      return new Promise((resolve, reject) => {
        dashboardApi.getTagStatistics(data).then(res => {
          if (res.code === 2000) {
            resolve(res.data)
          } else {
            reject(res.message)
          }
        })
      })
    },

  }
}

export default dashboard
